import React, { useState, useEffect, useRef } from 'react';
import './timeline.css';
import axios from 'axios';
import profile_logo from '../../image/Vector.png'
import { Link, useNavigate, useLocation } from 'react-router-dom';
// import { FaThumbsUp } from '@fortawesome/free-solid-svg-icons';
// import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';

import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import upload_img_logo from '../../image/photo-gallery 1.png'
import upload_video_logo from '../../image/video-calling 1.png'
import profile_display from '../../image/no_profile.jpeg'
import group from '../../image/Group 100.svg'
import Modal from './model'
import ShareButton from '../share_button/share_button';
import like_share from '../../image/like_share.png'
import VideoPopup from '../Contest/VideoPopup';
import participate_logo from '../../image/participate_logo.svg';
import filter_logo from '../../image/filter_logo.svg'
import arrow_up from '../../image/arrow_up.svg'
import arrow_down from '../../image/arrow_down.svg'
import mask from '../../image/footer-logo-2 2.svg'
import Resizer from 'react-image-file-resizer';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function Timeline() {


  const navigate = useNavigate();
  const [top_five, setTop_five] = useState([])
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchResultsSidebarOpen, setSearchResultsSidebarOpen] = useState(false);
  const [mediaType, setMediaType] = useState('images');
  const [generalType, setGeneralType] = useState('');
  const [posts, setPosts] = useState([]);
  const [likedPosts, setLikedPosts] = useState(new Map());
  const unique_id = localStorage.getItem('uniqueId')
  const [showModal1, setShowModal1] = useState(false);
  const [profile_change, setProfile_change] = useState(new Map())
  const [type, setType] = useState('image_path')
  const [activeTab, setActiveTab] = useState('Short Films Mania 2024');
  const [selectedVideo1, setSelectedVideo1] = useState(null);
  const [loadingStates, setLoadingStates] = useState(new Map());
  const [renderimg, setRenderimg] = useState(false);
  const [showFollowersFeed, setShowFollowersFeed] = useState(true);
  const [showCommunityContent, setShowCommunityContent] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(authStatus === 'true');
    if (authStatus != 'true') {
      navigate('/')
    }

    //check if membership form is filled or not, if not filled, navigate to membership form
    (async () => {
      try {
        const response = await axios.get(`https://backend.clicktalksnow.com/get_is_membership_form_filled/${unique_id}`);
        if (response) {
          if(!response.data.is_membership_form_filled){
            navigate('/membership-form')
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    })();
  }, []);

  const tabs = ['Short Films Talent Hunt 2024-25', 'Dance Talent Hunt 2024-25', 'Photography Talent Hunt 2024-25', 'Fashion Talent Hunt 2024-25', 
    'StandUp Comedy Talent Hunt 2024-25', 'Singing Talent Hunt 2024-25', 'Acting Talent Hunt 2024-25'];
  const handleReadMoreClick = (video) => {
    setSelectedVideo1(video);
  };
  useEffect(() => {
    // Disable right-click context menu
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    // Disable common keyboard shortcuts for opening developer tools
    const handleKeyDown = (e) => {
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && (e.key === 'I' || e.key === 'J' || e.key === 'C' || e.key === 'U')) ||
        (e.ctrlKey && e.key === 'U')
      ) {
        e.preventDefault();
      }
    };

    // Detect if developer tools are open and take action
    const handleDevToolsOpen = () => {
      const element = new Image();
      Object.defineProperty(element, 'id', {
        get: function () {
          alert('Developer tools are open!');
          window.location.replace('about:blank'); // Redirect to a blank page
        },
      });
      console.log(element);
    };

    // Add event listeners
    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);
    handleDevToolsOpen();

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const location = useLocation();

  const handleClosePopup1 = () => {
    setSelectedVideo1(null);
  };
  // const userProfilesMap = new Map();
  // const [file, setFile] = useState(null);

  const handleOpenModal1 = () => {
    setShowModal1(true);
  };
  const ffmpeg = new FFmpeg({ log: true });

  const urlToBlob = async (url) => {
    const response = await fetch(url);
    return await response.blob();
  };

  const resizeImage = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300, // max width
        300, // max height
        'JPEG', // format
        100, // quality
        0, // rotation
        (uri) => {
          resolve(uri);
        },
        'base64' // output type
      );
    });
  };

  const render = (value) => {
    setRenderimg(value)
  }

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };

  const userName = localStorage.getItem('name');



  useEffect(() => {
    // fetchtopfive('  https://backend.clicktalksnow.com/top_five_results/')
    profile_images_display()
  }, []);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      let url = '';
      switch (activeTab) {
        case 'Short Films Talent Hunt 2024-25':
          url = '  https://backend.clicktalksnow.com/top_five_results/?year=2024';
          break;
        case 'Dance Talent Hunt 2024-25':
          url = '  https://backend.clicktalksnow.com/top_five_results_dance/?year=2024';
          break;
        case 'Photography Talent Hunt 2024-25':
          url = '  https://backend.clicktalksnow.com/top_five_results_photo/?year=2024';
          break;
        case 'Fashion Talent Hunt 2024-25':
          url = '  https://backend.clicktalksnow.com/top_five_results_fashion/?year=2024';
          break;
        case 'StandUp Comedy Talent Hunt 2024-25':
          url = '  https://backend.clicktalksnow.com/top_five_results_standup/?year=2024';
          break;
        case 'Singing Talent Hunt 2024-25':
          url = '  https://backend.clicktalksnow.com/top_five_results_singing/?year=2024';
          break;
        case 'Acting Talent Hunt 2024-25':
          url = '  https://backend.clicktalksnow.com/top_five_results_acting/?year=2024';
          break;
        default:
          url = '  https://backend.clicktalksnow.com/top_five_results/?year=2024';
      }
      try {
        fetchtopfive(url)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [activeTab]);


  const fetchtopfive = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
      });

      const data = await response.json()
      setTop_five(data.result)

    } catch (error) {

    }
  }

  const profile_images_display = async () => {
    try {
      const response = await fetch(`  https://backend.clicktalksnow.com/get_user_profile_details`, {
        method: 'GET',
      });
      const data = await response.json()

      const userProfilesMap = new Map();
      data.result.forEach(user => {
        userProfilesMap.set(user.user_id, user.profile_image);
      });

      setProfile_change(userProfilesMap)
    } catch (error) {

    }
  }

  const toggleSidebar = () => {
    setMobileSidebarOpen(!isMobileSidebarOpen);
    if (!isMobileSidebarOpen) {
      setMobileNavbarOpen(false);
    }
  };

  const toggleNavbar = () => {
    setMobileNavbarOpen(!isMobileNavbarOpen);
    if (!isMobileNavbarOpen) {
      setMobileSidebarOpen(false);
    }
  };

  const handleSignOut = () => {
    localStorage.clear();
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query.trim()) {
      fetch('  https://backend.clicktalksnow.com/search_bar/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ search_text: query })
      })
        .then(response => response.json())
        .then(data => {

          setSearchResults(data.Names);
          setSearchResultsSidebarOpen(data.Names.length > 0); // Assuming the API directly returns the list of names

        })
        .catch(error => console.error('Error fetching search results:', error));
    } else {
      setSearchResults([]);
      setSearchResultsSidebarOpen(false); // Clear results if the query is cleared
    }
  };

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  const toggleSearchResultsSidebar = () => {
    setSearchResultsSidebarOpen(!isSearchResultsSidebarOpen);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const url = mediaType === 'images'
          ? `  https://backend.clicktalksnow.com/images/following/${unique_id}/`
          : `  https://backend.clicktalksnow.com/videos/following/${unique_id}/`;
        const postsResponse = await fetch(url);
        const postsData = await postsResponse.json();
        setPosts(postsData.result);


        const likedPostsData = await fetchInitialLikedStatus(postsData.result, unique_id, mediaType, mediaType);
        setLikedPosts(likedPostsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    const fetchGeneralMedia = async (type) => {
      const generalUrls = {
        'images': `  https://backend.clicktalksnow.com/general/get_images/${unique_id}`,
        'videos': `  https://backend.clicktalksnow.com/general/get_videos/${unique_id}`,
        'youtube_videos': `  https://backend.clicktalksnow.com/general/get_youtube_videos/${unique_id}`
      };

      try {
        const response = await fetch(generalUrls[type]);
        const data = await response.json();

        setPosts(data.result);


        const likedPostsData = await fetchInitialLikedStatus(data.result, unique_id, 'general', type
        );
        setLikedPosts(likedPostsData);
      } catch (error) {
        console.error(`Error fetching ${type}:`, error);
      }
    };

    if (mediaType === 'general') {
      fetchGeneralMedia(generalType);
    } else {
      fetchPosts();
    }
  }, [mediaType, generalType, unique_id]);


  const fetchInitialLikedStatus = async (posts, unique_id, mediaType, type) => {
    try {
      const likedPostsData = new Map();

      // Fetch initial liked status for all posts
      const likeResponse = await fetch(`  https://backend.clicktalksnow.com/${type}/check_likes1/${unique_id}`, {
        method: 'GET',
      });
      const likeData = await likeResponse.json();

      likeData.result.forEach(item => {
        const key = `${item.user_id}-${item.image_path || item.video_path || item.youtube_path}-${unique_id}`;
        likedPostsData.set(key, true);
      });


      return likedPostsData;
    } catch (error) {
      console.error('Error fetching initial liked status:', error);
      return new Map();
    }
  };

  const handleFollowersFeedToggle = () => {
    setShowFollowersFeed(!showFollowersFeed);
    if (!showFollowersFeed) {
      setShowCommunityContent(false);
      setMediaType('images');
    }
  };

  const handleCommunityContentToggle = () => {
    setShowCommunityContent(!showCommunityContent);
    if (!showCommunityContent) {
      setShowFollowersFeed(false);
      setMediaType('general');
      setGeneralType('images'); // Default selection for general type
    }
  };



  const handleLike = async (post) => {



    const key = mediaType === 'general'
      ? `${post.unique_id}-${post.image_path || post.video_path || post.youtube_path}-${localStorage.getItem('uniqueId')}`
      : `${post.user_id}-${post.image_path || post.video_path || post.youtube_path}-${localStorage.getItem('uniqueId')}`;

    if (loadingStates.get(key)) return; // Prevent multiple clicks for this post

    setLoadingStates(prevLoadingStates => new Map(prevLoadingStates.set(key, true)));
    try {
      const isLiked = likedPosts.get(key);
      const likeData = {
        unique_id,
        id: post.id
      };
      if (mediaType === 'images' || (mediaType === 'general' && generalType === 'images')) {
        if (mediaType === 'images') { likeData.user_id = post.user_id }
        if (mediaType === 'general' && generalType === 'images') {
          likeData.user_id = post.unique_id
        }
        likeData.image_path = post.image_path;
      } else if (mediaType === 'videos' || (mediaType === 'general' && generalType === 'videos')) {
        if (mediaType === 'videos') { likeData.user_id = post.user_id }
        if (mediaType === 'general' && generalType === 'videos') {
          likeData.user_id = post.unique_id
        }
        likeData.video_path = post.video_path;
      } else if (mediaType === 'general' && generalType === 'youtube_videos') {
        if (mediaType === 'general' && generalType === 'youtube_videos') {
          likeData.user_id = post.unique_id
        }
        likeData.youtube_path = post.youtube_path;
      }

      const type = mediaType === 'general' ? generalType : mediaType;
      const likeUrl = isLiked ? `  https://backend.clicktalksnow.com/${type}/unlikes/` : `  https://backend.clicktalksnow.com/${type}/likes/`;
      const response = await fetch(likeUrl, {
        method: isLiked ? 'DELETE' : 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(likeData)
      });

      if (response.ok) {
        setLikedPosts(prevLikedPosts => new Map(prevLikedPosts.set(key, !isLiked)));
        setPosts(prevPosts =>
          prevPosts.map(p =>
            p.id === post.id
              ? { ...p, likes: p.likes + (isLiked ? -1 : 1) }
              : p
          )
        );
      } else {
        console.error('Failed to update like status');
      }
    } catch (error) {
      console.error('Error updating like status:', error);
    } finally {
      setLoadingStates(prevLoadingStates => new Map(prevLoadingStates.set(key, false)));
    }
  };


  return (
    <div className='timeline_sid'>
      <div className="container">
        <div className="navbar">
          <div className="navbar-menu-icon" onClick={toggleSidebar}>
            &#9776;
          </div>

          <div className="navbar-logo1">
            <img src={mask}></img>
            <Link style={{ textDecoration: "none", color: "#D32F2F" }} to="/">CLICK TALKS </Link></div>
          <div className='searching'>
            {isAuthenticated && (
             <div className="search-bar">
             <FontAwesomeIcon icon={faSearch} className="search-icon" />
             <input
               type="text"
               placeholder="Search"
               className="search-input"
               value={searchQuery}
               onChange={handleSearch}
             />
           </div>)}
          </div>
          <div className="navbar-links">
            {isAuthenticated && (
              <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>

            )}

            <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
            <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

            {isAuthenticated && userName ? (
              <>
                <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                <Link to="/" onClick={handleSignOut}>Sign Out</Link>
              </>
            ) : (
              <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
            )}
          </div>
          <div className="navbar-menu" onClick={toggleNavbar}>
            &#x22EE;
          </div>
          {isMobileNavbarOpen && (

            <div className="mobile-navbar-links">
              <Link to='/'>Home</Link>
              {isAuthenticated && (
                <Link to="/timeline" className={location.pathname === '/timeline' ? 'active' : ''}>Timeline</Link>
              )}

              <Link to="/Contest" className={location.pathname === '/Contest' ? 'active' : ''}>Contest</Link>
              <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>About Us</Link>

              {isAuthenticated && userName ? (
                <>
                  <Link to="/myprofile" className={location.pathname === '/myprofile' ? 'active' : ''}>{userName}</Link>
                  <Link to="/" onClick={handleSignOut}>Sign Out</Link>
                </>
              ) : (
                <Link to="/register" className={location.pathname === '/register' ? 'active' : ''}>Register</Link>
              )}

            </div>
          )}

        </div>
        <div className="content">
          <div className={`sidebar ${isMobileSidebarOpen ? 'open' : ''}`}>
            <div className='welcome'>
              <h2>Welcome,{userName}!</h2>
              <p>Catch up the latest updates </p>
            </div>
            <div className="upload-works-container">
              <div className='image_upload_timeline'>
                <button className="upload-button" onClick={handleOpenModal1} >+</button>
                <h3>Upload Your Content</h3>
              </div>
              <div className="upload-content">

                <p>Looking to showcase your creations? share them here!</p>
                <div className='dashed_line_try'></div>
                <div className="upload-options">
                  <button className="option-button" onClick={handleOpenModal1}>
                    <img src={upload_img_logo} alt="Images" />
                    Images
                  </button>
                  <button className="option-button" onClick={handleOpenModal1}>
                    <img src={upload_video_logo} alt="Videos" />
                    Videos
                  </button>
                </div>
              </div>
            </div>

            <Link to='/contest'  style={{textDecoration:'none'}}><div className='participate_logo'>
              <div><img src={participate_logo}></img></div>
              <div style={{color:'#08000E',textDecoration:'none'}}>Participate in contests</div>
            </div></Link>

            <div className="filters">
              <div className='filter_apply'>
                <img src={filter_logo}></img>
                <h3 style={{margin:'0',fontWeight: "bold",
    color: "#020100"}}><i className="filter-icon" /> Filters</h3>
              </div>
              <div>
                <h4 onClick={handleFollowersFeedToggle} className='adjust' style={{color: "#020100"}}>
                  Followers feed <img className="arrow-icon" src={showFollowersFeed ? arrow_up : arrow_down} alt="arrow" />
                </h4>
                {showFollowersFeed && (
                  <div className="selected_type" style={{ marginLeft: '20px' }}>
                    <label>
                      <input
                        type="radio"
                        value="images"
                        checked={mediaType === 'images' && generalType === ''}
                        onChange={() => {
                          setMediaType('images');
                          setGeneralType('');
                          setType('image_path');
                        }}
                      /> Image
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="videos"
                        checked={mediaType === 'videos' && generalType === ''}
                        onChange={() => {
                          setMediaType('videos');
                          setGeneralType('');
                          setType('video_path');
                        }}
                      /> Video
                    </label>
                  </div>
                )}
              </div>
              <div>
                <h4 onClick={handleCommunityContentToggle} className='adjust' style={{color: "#020100"}}>
                  Community Content <img className="arrow-icon" src={showCommunityContent ? arrow_up : arrow_down} alt="arrow" />
                </h4>

                {showCommunityContent  && (
                  
                  <div className="selected_type"  style={{ marginLeft: '20px' }}>
                    <label>
                      <input
                        type="radio"
                        value="images"
                        checked={generalType === 'images'}
                        onChange={() => {
                          setGeneralType('images');
                          setType('image_path');
                        }}
                      /> Images
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="videos"
                        checked={generalType === 'videos'}
                        onChange={() => {
                          setGeneralType('videos');
                          setType('video_path');
                        }}
                      /> Videos
                    </label>
                    {/* <label>
                      <input
                        type="radio"
                        value="youtube_videos"
                        checked={generalType === 'youtube_videos'}
                        onChange={() => {
                          setGeneralType('youtube_videos');
                          setType('youtube_path');
                        }}
                      /> YouTube Videos
                    </label> */}
                  </div>
                 
                )}
              
              </div>
            </div>
          </div>
          <Modal show={showModal1} handleClose={handleCloseModal1} sr={render} />
          <div className="main-content">
            <div className="top-works-container">
              {/* <button className="scroll-button left" onClick={scrollLeft}>
                  <FontAwesomeIcon icon={faArrowLeft} />
                </button> */}
              <div className='group_img'>
                <span><img src={group}></img></span>
                <h2>Showcasing Highlights</h2>
              </div>
              <div className="tabs">
                {tabs.map(tab => (
                  <button
                    key={tab}
                    className={`tab ${activeTab === tab ? 'active' : ''}`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </button>
                ))}
              </div>
              <div className="top-works">

                <div className="work-group" ref={scrollRef}>

                  {top_five.map(video => (
                    <div className="card" key={video.id}>

                      <div style={{ textAlign: 'left' }} className="video-title">

                        <div className='vedio_controls'><span><img src={profile_logo}></img></span>
                        <div>
                                <p style={{marginTop:'10px',marginBottom:'1px',}}>{video.name}</p>
                                <span style={{color:'#888888',marginTop:'2px',fontSize:'14px'}}>{video.clg}</span>
                                </div>
                          </div>
                      </div>
                      <iframe
                        width="100%"
                        height="200"
                        src={
                          video.youtube_path.includes("drive.google.com")
                            ? video.youtube_path.replace(/(\/file\/d\/[^/]+)\/.*/, "$1/preview")
                            : video.youtube_path
                        }
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={video.name}
                      ></iframe>
                      <div className="card-content">
                        <div className="likes">
                          <div className='sharing'>
                            <div className="heart"><FontAwesomeIcon icon={faThumbsUp} /></div>
                            <div>{video.likes}</div>
                          </div>
                          <div style={{
                      width: '24px',cursor:'pointer'
                    }}><ShareButton url={`https://www.civilforce.in/contest/?subitem=${encodeURIComponent(activeTab)}&id=${video.id}`} title={'contest'} />

                        </div>
                        </div>
                      </div>
                      <div className='line1'></div>
                      <div className='descrpt'>
                        <h3>The Explorer</h3>
                        <span>{video.descrpt.split(" ").slice(0, 20).join(" ") + (video.descrpt.split(" ").length > 20 ? "... " : " ")}</span>
                        <span className='read_me_more' style={{ color: 'black', fontWeight: 'bold' }} onClick={() => handleReadMoreClick(video)} >Read more</span>
                      </div>
                    </div>
                  ))}
                  {selectedVideo1 && <VideoPopup video={selectedVideo1} onClose={handleClosePopup1} />}

                </div>

              </div>
              {/* <button className="scroll-button right" onClick={scrollRight}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </button> */}
            </div>

            <div className="user-profile">
              <div className="posts-container">
                {posts !== undefined && posts.length > 0 && posts.map(post => (
                  
                  <div className="post" key={post.id}>
                    <div className='display_details'>
                      <div className='profile_imag_atsch'>
                        <span><img  src={`${mediaType !== 'general'
                          ? profile_change.get(post.user_id)
                          : profile_change.get(post.unique_id)
                          }`}
                          style={{ width: "50px", height: "50px", borderRadius: "50%" }}></img></span>
                        <div className="post-header"> <Link to={`/userprofile?name=${post.name}`} style={{ textDecoration: "none", color: "black" }}>{post.name}</Link></div>
                      </div>
                    </div>

                    {
                      mediaType === 'images' ? (
                        <img src={`${post.image_path}`} alt="Post" className="post-image"  />
                      ) : mediaType === 'videos' ? (
                        <iframe src={post.video_path} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen className="post-video"></iframe>
                      ) : null
                    }
                    {
                      mediaType === 'general' && (
                        generalType === 'images' && post.image_path ? (
                          <img src={`${post.image_path}`} alt="Post" className="post-image"  />
                        ) : generalType === 'videos' && post.video_path ? (
                          <iframe src={post.video_path} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen className="post-video"></iframe>
                        ):null
                        // ) : generalType === 'youtube_videos' && post.youtube_path ? (
                        //   <iframe src={post.youtube_path} frameborder="0" allow="autoplay; encrypted-media" allowfullscreen className="post-video"></iframe>
                        // ) : null
                      )
                    }
                    <div className="post-interactions">
                      <div className='sharing'>
                        <span
                          onClick={() => handleLike(post)}
                          style={{
                            cursor: loadingStates.get(
                              mediaType === 'general'
                                ? `${post.unique_id}-${post.image_path || post.video_path || post.youtube_path}-${localStorage.getItem('uniqueId')}`
                                : `${post.user_id}-${post.image_path || post.video_path || post.youtube_video_url}-${localStorage.getItem('uniqueId')}`
                            ) ? 'not-allowed' : 'pointer',
                            pointerEvents: loadingStates.get(
                              mediaType === 'general'
                                ? `${post.unique_id}-${post.image_path || post.video_path || post.youtube_path}-${localStorage.getItem('uniqueId')}`
                                : `${post.user_id}-${post.image_path || post.video_path || post.youtube_video_url}-${localStorage.getItem('uniqueId')}`
                            ) ? 'none' : 'auto',
                            color: likedPosts.get(
                              mediaType === 'general'
                                ? `${post.unique_id}-${post.image_path || post.video_path || post.youtube_path}-${localStorage.getItem('uniqueId')}`
                                : `${post.user_id}-${post.image_path || post.video_path || post.youtube_video_url}-${localStorage.getItem('uniqueId')}`
                            )
                              ? 'red'
                              : 'black'
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              likedPosts.get(
                                mediaType === 'general'
                                  ? `${post.unique_id}-${post.image_path || post.video_path || post.youtube_path}-${localStorage.getItem('uniqueId')}`
                                  : `${post.user_id}-${post.image_path || post.video_path || post.youtube_video_url}-${localStorage.getItem('uniqueId')}`
                              )
                                ? faThumbsUp // Icon name for liked post
                                : faThumbsUp

                            }
                          />
                        </span>

                        <span>{post.likes}</span>
                      </div>
                      <div style={{cursor:'pointer'}} ><ShareButton url={post[`${type}`]} title={post.name} /></div>
                      {/* <div><ShareButton url={'http://localhost:3000/timeline'} title={'sid'}/></div> */}

                    </div>

                  </div>
                ))}


              </div>
            </div>
          </div>
        </div>

        {isSearchResultsSidebarOpen && (
          <div className="search-results-sidebar">
            <button onClick={toggleSearchResultsSidebar}>Close</button>
            {searchResults.map(result => (
              <div className="search-result">
                <Link to={`/userprofile?name=${result}`}>
                  <img src={profile_logo} alt="Profile Logo" className="profile-logo" />
                  <span className="profile-name">{result}</span>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default Timeline;
